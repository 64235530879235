var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"past_part",staticClass:"past_part main_padding"},[_c('h5',{staticClass:"sub_title txt-bold txt-center"},[_vm._v(" 歷年實績 ")]),(_vm.achievementData.length > 0)?_c('div',{staticClass:"itemlist w1400"},_vm._l((_vm.achievementData),function(item,i){return _c('div',{key:("achievementItem_" + i + "_" + (item._id)),staticClass:"item"},[_c('h6',{staticClass:"txt-bold"},[_vm._v(_vm._s(item.content))]),_c('p',{staticClass:"txt-gray"},[_vm._v("完工日期 : "+_vm._s(item.take_year))])])}),0):_vm._e(),_c('div',{staticClass:"w1400"},[_c('div',{staticClass:"pagination txt-right txt-white"},[(_vm.nowPage > 1)?_c('router-link',{staticClass:"to_prev",attrs:{"to":{
          name: 'AchievementPast',
          params: { page: _vm.nowPage - 1 },
        }}},[_c('img',{attrs:{"src":require("@/assets/img/public/arrow-w.svg"),"alt":""}})]):_vm._e(),_vm._l((_vm.pagination),function(item,i){return [(typeof(item) === 'number')?_c('router-link',{key:("page_" + i + "_" + _vm.pagination),attrs:{"to":{
            name: 'AchievementPast',
            params: { page: item },
          }}},[_vm._v(" "+_vm._s(item)+" ")]):_c('span',{key:("page_" + i + "_" + _vm.pagination),staticClass:"txt-gray"},[_vm._v(" ... ")])]}),(_vm.nowPage <= _vm.totalPage - 1)?_c('router-link',{staticClass:"to_next",attrs:{"to":{
          name: 'AchievementPast',
          params: { page: _vm.nowPage + 1 },
        }}},[_c('img',{attrs:{"src":require("@/assets/img/public/arrow-w.svg"),"alt":""}})]):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }