<template>
  <div class="past_part main_padding" ref="past_part">
    <h5 class="sub_title txt-bold txt-center">
      歷年實績
    </h5>

    <div
      class="itemlist w1400"
      v-if="achievementData.length > 0">
        <div
          class="item"
          v-for="(item, i) in achievementData"
          :key="`achievementItem_${i}_${item._id}`">
            <h6 class="txt-bold">{{item.content}}</h6>
            <p class="txt-gray">完工日期 : {{item.take_year}}</p>
        </div>
    </div>

    <div class="w1400">
      <div class="pagination txt-right txt-white">
        <router-link
          v-if="nowPage > 1"
          :to="{
            name: 'AchievementPast',
            params: { page: nowPage - 1 },
          }"
          class="to_prev">
            <img src="@/assets/img/public/arrow-w.svg" alt="">
        </router-link>
        <template v-for="(item, i) in pagination">
          <router-link
            v-if="typeof(item) === 'number'"
            :key="`page_${i}_${pagination}`"
            :to="{
              name: 'AchievementPast',
              params: { page: item },
            }">
              {{item}}
          </router-link>
          <span
            v-else
            :key="`page_${i}_${pagination}`"
            class="txt-gray">
              ...
          </span>
        </template>
        <router-link
          v-if="nowPage <= totalPage - 1"
          :to="{
            name: 'AchievementPast',
            params: { page: nowPage + 1 },
          }"
          class="to_next">
            <img src="@/assets/img/public/arrow-w.svg" alt="">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import requestApi from '@/lib/http/index';

export default {
  name: 'Past',
  components: {
  },
  props: {
    page: {
      type: [Number, String],
      default: '1',
    },
  },
  data() {
    return {
      newsMaxCount: 8,
      achievementData: [],
      achievementTotal: 0,
    };
  },
  watch: {
    $route(to, form) {
      console.log(to, form);
      if (
        to.params.page < 1
        || to.params.page > this.totalPage
      ) {
        this.$router.push(
          {
            name: 'AchievementPast',
            params: { page: 1 },
          },
        );
      } else {
        this.getData(8, (this.nowPage - 1) * 8);

        window.scrollTo({
          top: this.$refs.past_part.offsetTop + 50,
          behavior: 'smooth',
        });
      }
    },
  },
  computed: {
    nowPage() {
      return Number(this.page);
    },
    totalPage() {
      return Math.ceil(this.achievementTotal / this.newsMaxCount);
    },
    pagination() {
      const showPages = [];

      if (this.nowPage >= 2) {
        showPages.push(1);
      }
      if (this.nowPage >= 3) {
        showPages.push('...');
      }

      for (let i = 0; i < 3; i += 1) {
        if (this.nowPage + i <= this.totalPage) {
          showPages.push(this.nowPage + i);
        }
      }

      if (this.nowPage < this.totalPage - 3) {
        showPages.push('...');
      }
      if (this.nowPage < this.totalPage - 2) {
        showPages.push(this.totalPage);
      }

      return showPages;
    },
  },
  methods: {
    async getData(limit, skip) {
      const result = await requestApi(
        'achievement.index',
        {
          limit,
          skip,
          filter: { status: 'Y' },
          sort: { _created: -1 },
        },
      );
      console.log(result);

      this.achievementData = [...result.entries];
      this.achievementTotal = result.total;

      if (result.entries.length === 0) {
        this.$router.push({
          name: 'AchievementPast',
          params: { page: 1 },
        });
      }
    },
  },
  created() {
    this.getData(8, (this.nowPage - 1) * 8);

    if (
      !this.nowPage
      // eslint-disable-next-line no-restricted-globals
      || isNaN(this.nowPage)
      || this.$route.params.page < 1
    ) {
      this.$router.push(
        {
          name: 'AchievementPast',
          params: { page: 1 },
        },
      );
    }
  },
};
</script>
